import { useState, FC } from 'react';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import CustomButton from 'components/CustomButton';
import ZelcoreWalletSelectDialog from 'components/Modals/ZelcoreWalletSelectDialog';
import { getAccounts, openZelcore } from 'utils/zelcore';

import closeIcon from 'assets/img/close-green.png';
import styles from 'assets/jss/components/dialogStyles';
const useStyles = makeStyles(styles);

interface Props {
  open: boolean;
  handleClose: () => void
}

const GetZelcoreWalletDialog: FC<Props> = (props) => {

  const { open, handleClose } = props;
  const classes = useStyles();

  const [openZecloreSellectDialog, setOpenZecloreSellectDialog] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<string[]>([]);
  
  const [isGettingAddress, setIsGettingAddress] = useState<boolean>(false);

  const getAccountsFromWallet = async () => {
    setLoading(true);
    openZelcore();
    const getAccountsResponse = await getAccounts();
    if (getAccountsResponse.status === 'success') {
      setOpenZecloreSellectDialog(true);
      handleClose();
      setAccounts(getAccountsResponse.data);
    } else {
      /* walletError(); */
    }
    setLoading(false);
  };
  
  return (
    <>
      <Dialog onClose={() => {}} open={open} className={classes.dialog}>
        <Box className={classes.dialogContents}>
          <Box className={clsx(classes.header, classes.mintHeader)} position='relative'>
            <Typography variant='h6' fontWeight={800} fontFamily='Inter'>
              Connect wallet
            </Typography>
            <IconButton className={classes.closeBtn} onClick={handleClose}>
              <img src={closeIcon} width={14} height={14} alt="" />
            </IconButton>
          </Box>

          <Box>
            <Typography variant='body1' align='center'>
            Please make sure the KDA account provided is controlled by your Zelcore wallet.
            </Typography>
            <Box className={classes.divider} my={4}></Box>
            <Typography variant='body1' align='center'>
              When submitting a transaction, Zelcore will show you a preview within the wallet before signing.
            </Typography>
            <Box textAlign='center' mt={4} className={classes.mintBtn}>
              { !loading && 
                <CustomButton fullWidth variant='greenFill' 
                  onClick={() => { setIsGettingAddress(true); getAccountsFromWallet()}}>
                  { !isGettingAddress ? 'Get Zelcore Account': 'Retry' }
                </CustomButton>
              }
              { loading && isGettingAddress &&
                <CustomButton fullWidth variant='greenFill'>
                  <div className='loader'>Get addresses</div>
                </CustomButton>
              }
            </Box>
          </Box>
        </Box>
      </Dialog>

      <ZelcoreWalletSelectDialog
          open={openZecloreSellectDialog}
          handleClose={() => setOpenZecloreSellectDialog(false)}
          accounts={accounts}
        />
    </>
  )
}

export default GetZelcoreWalletDialog;
