// @ts-ignore
import Pact from 'pact-lang-api';
import { NETWORK } from '../constants/contextConstants';

export const mkReq = function (cmd: any) {
  return {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(cmd),
  };
};

export const parseRes = async function (raw: any) {
  const rawRes = await raw;
  const res = await rawRes;
  if (res.ok) {
    const resJSON = await rawRes.json();
    return resJSON;
  } else {
    const resTEXT = await rawRes.text();
    return resTEXT;
  }
};

export const wait = async (timeout: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
};

export const handleError = (error: any) => {
  // console.log(`ERROR: ${JSON.stringify(error)}`);
  return { errorMessage: 'Unhandled Exception' };
};

export const listen = async (reqKey: string) => {
  let time = 500;
  let pollRes;
  while (time > 0) {
    await wait(5000);
    pollRes = await Pact.fetch.poll({ requestKeys: [reqKey] }, NETWORK);
    if (Object.keys(pollRes).length === 0) {
      time = time - 5;
    } else {
      time = 0;
    }
  }
  if (pollRes && pollRes[reqKey]) {
    return pollRes[reqKey];
  }
  return null;
};

export const is_hexadecimal = (str: string) => {
  const regexp = /^[0-9a-fA-F]+$/;
  if (regexp.test(str)) return true;
  else return false;
};

export const checkKey = (key: string) => {
  try {
    let keyToCheck = key;
    if (key.startsWith('k:')) {
      keyToCheck = key.split(':')[1];

      if (keyToCheck.length === 64) {
        return true;
      }
      if (!is_hexadecimal(keyToCheck)) {
        return false;
      }
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const convertLocalTime = (time: string | '') => {
  if (time === undefined || time === '' ){
    return '00:00:00:00';
  } else {
    let firstIndex = time.indexOf('G');
    if (firstIndex) {
      return time.slice(0, firstIndex) + '(Local time)';
    } else {
      return time;
    }
  }
}