import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBUE9wFG5ojxWqwOBh4Q8FgIS19S6IXbdk",
  authDomain: "rileytool-8a4e3.firebaseapp.com",
  projectId: "rileytool-8a4e3",
  storageBucket: "rileytool-8a4e3.appspot.com",
  messagingSenderId: "43180714099",
  appId: "1:43180714099:web:b7d48fdce3c2cb916c6206"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export { db };
