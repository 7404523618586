
export const WALLET = {
  KADDEX_WALLET: {
    id: 'KADDEX_WALLET',
    name: 'X-Wallet',
    signMethod: 'wallet',
    getAccountsUrl: '',
  },
  ZELCORE: {
    id: 'ZELCORE',
    name: 'Zelcore',
    signMethod: 'wallet',
    getAccountsUrl: 'http://127.0.0.1:9467/v1/accounts',
  },
  CHAINWEAVER: {
    id: 'CHAINWEAVER',
    name: 'Chainweaver',
    signMethod: 'wallet',
    getAccountsUrl: 'http://127.0.0.1:9467/v1/accounts',
  },
};

export interface WalletObj {
  id: string,
  name: string,
  signMethod: string,
  getAccountsUrl: string,
}

