import { FC, useEffect, useState } from 'react';
// @ts-ignore
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

import CustomButton from 'components/CustomButton';
import closeIcon from 'assets/img/close-green.png';
import styles from 'assets/jss/components/dialogStyles';

import { CHAIN_ID } from 'constants/contextConstants';
import { useAccountContext } from 'contexts';
import { getAccountBalances } from 'utils/pact'

const useStyles = makeStyles(styles);

interface Props {
  open: boolean;
  handleClose: () => void,
  handleDisconnectDialog: () => void
}

const AccountDetailDialog: FC<Props> = (props) => {

  const { open, handleClose, handleDisconnectDialog } = props;
  const classes = useStyles();
  const { account } = useAccountContext();

  const [accounuBalances, setAccountBalances] = useState<Array<Number>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleDisconnectWallet = () => {
    handleClose();
    handleDisconnectDialog();
  }

  useEffect(() => {
    const getBalances = async () => {
      if (account.account) {
        setLoading(true);
        let balancesArray = await getAccountBalances(account.account);
        setAccountBalances(balancesArray);
        setLoading(false);
      }
    }

    account.account && getBalances();
  }, [account.account]);

  return (
    <Dialog onClose={() => { }} open={open} className={classes.dialog}>
      <Box className={classes.dialogContents}>
        <Box className={clsx(classes.header, classes.mintHeader)} position='relative'>
          <Typography variant='h6' fontWeight={800} fontFamily='Inter'>
            Account
          </Typography>
          <IconButton className={classes.closeBtn} onClick={handleClose}>
            <img src={closeIcon} width={14} height={14} alt="" />
          </IconButton>
        </Box>

        <Box>
          <Box className={classes.balancesBox}>
            {!loading &&
              accounuBalances.map((item, index) => (
                <Box display='flex' justifyContent='space-between' key={index}>
                  <Typography variant='body1' align='center' color={parseInt(CHAIN_ID) == index ? '#01DE8E' : ''}>
                    Chain {index}
                  </Typography>

                  <Typography variant='body1' align='center' color={parseInt(CHAIN_ID) == index ? '#01DE8E' : ''}>
                    {item.toString()} KDA
                  </Typography>
                </Box>
              ))
            }

            {
              loading &&
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
              </Box>
            }

          </Box>
          <Box className={classes.divider} my={4}></Box>
          <Box textAlign='center' mt={4} className={classes.mintBtn} display='flex' gap='10px'>
            <CustomButton variant='gradientOutline' onClick={() => handleDisconnectWallet()}>
              Disconnect
            </CustomButton>
            <CustomButton variant='gradientFill' onClick={handleClose}>
              Cancel
            </CustomButton>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

export default AccountDetailDialog;
