export const reduceBalance = (balance: any, prec = 6) => {
  if (balance) {
    if (balance.int) balance = balance.int;
    if (balance.decimal) balance = balance.decimal;
    if (parseFloat(balance) % 1 === 0) {
      return parseInt(balance);
    }
    return Math.trunc(parseFloat(balance) * Math.pow(10, prec)) / Math.pow(10, prec);
  } else {
    return 0;
  } 
};

export const keepDecimal = (decimal: number | undefined) => {
  // @ts-ignore
  const num = decimal.toString().indexOf('.') === -1 ? `${decimal}.0` : decimal;
  return num;
};

export const getCorrectBalance = (balance: any) => {
  const balanceClean = !isNaN(balance) ? balance : balance.decimal;
  return balanceClean;
};


