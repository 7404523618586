/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, ReactNode } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// @ts-ignore
import Pact from 'pact-lang-api';
import { getCorrectBalance } from '../utils/reduceBalance';
import { CHAIN_ID, creationTime, GAS_PRICE, NETWORK } from '../constants/contextConstants';
import useLocalStorage from '../hooks/useLocalStorage';
import { reduceToken } from 'utils/reduceToken';
import { useWalletContext } from '.';
import { WalletObj } from 'constants/wallet';

export type accountObj = {
  account: string, 
  guard: string, 
  balance: 0 
}

const initAccountSettings: accountObj = {
  account: 'primary',
  guard: '',
  balance: 0
}

export type AccountContextValue = {
  account: accountObj,
  setVerifiedAccount: (accountName: string, wallet: WalletObj | null) => void,
  logout: () => void,
}

// ** Create Context
export const AccountContext = createContext<AccountContextValue>({
  account: initAccountSettings,
  setVerifiedAccount: (accountName: string, wallet: WalletObj | null) => null,
  logout: () => null,
});

export const AccountProvider = ({ children }: { children: ReactNode }) => {

  const { setSelectedWallet } = useWalletContext();

  const [account, setAccount, removeAccount] = useLocalStorage('acct',JSON.stringify(initAccountSettings));

  const setVerifiedAccount = async (accountName: string, wallet: WalletObj | null ) => {
    try {
      let data = await Pact.fetch.local(
        {
          pactCode: `(coin.details ${JSON.stringify(accountName)})`,
          meta: Pact.lang.mkMeta('', CHAIN_ID, GAS_PRICE, 3000, creationTime(), 600),
        },
        NETWORK
      );

      if (data.result.status === 'success') {
        wallet && toast.success(`${wallet.name} was connected`);
        setAccount({
          ...data.result.data,
          balance: getCorrectBalance(data.result.data.balance),
        });
        if (wallet) {
          setSelectedWallet(wallet)
        }
      } else {
        setAccount({ account: accountName, guard: null, balance: 0 });
        toast.warning(`Please make sure the account ${reduceToken(accountName)} exist on chain ${CHAIN_ID} of the kadena blockchain`)
      }
    } catch (e) {
      console.log(e);
    }
  };

  const logout = () => {
    removeAccount();
    localStorage.removeItem('wallet');
    window.location.reload();
  };

  return <AccountContext.Provider value={{ account, setVerifiedAccount, logout }}>{children}</AccountContext.Provider>;
};

export const AccountConsumer = AccountContext.Consumer;
