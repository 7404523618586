export const CHAIN_ID = process.env.REACT_APP_KDA_CHAIN_ID || '1';
export const NETWORKID = process.env.REACT_APP_KDA_NETWORK_ID || 'testnet04';
export const GAS_PRICE = Number(process.env.REACT_APP_KDA_GAS_PRICE) || 0.0000001;
export const GAS_LIMIT = Number(process.env.REACT_APP_KDA_GAS_LIMIT) || 100000;
export const NETWORK_VERSION = process.env.REACT_APP_KDA_NETWORK_VERSION || '0.0';
export const NETWORK = `${process.env.REACT_APP_KDA_NETWORK}/chainweb/${NETWORK_VERSION}/${NETWORKID}/chain/${CHAIN_ID}/pact`;
export const creationTime = () => Math.round(new Date().getTime() / 1000) - 10;
export const RILEY_WALLET = process.env.REACT_APP_RILEY_WALLET;
export const COLLECTION_NAME = process.env.REACT_APP_FIREBASE_COLLECTION;


