import { useState, FC } from 'react';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import CustomButton from 'components/CustomButton';
import closeIcon from 'assets/img/close-green.png';
import styles from 'assets/jss/components/dialogStyles';
import { WALLET } from 'constants/wallet';
import { useAccountContext } from 'contexts';
import { checkKey } from 'utils/functions';

const useStyles = makeStyles(styles);

interface Props {
  open: boolean;
  handleClose: () => void
}

const ChainweaverWalletDialog: FC<Props> = (props) => {

  const { open, handleClose } = props;
  const classes = useStyles();

  const account = useAccountContext();

  const [accountId, setAccountId] = useState<string>('');
  useState(false);

  const resetValues = () => {
    setAccountId('');
  };

  const handleModalClose = () => {
    resetValues();
    handleClose();
  };

  const handleConnect = async () => {
    await account.setVerifiedAccount(accountId, WALLET.CHAINWEAVER);
    handleModalClose();
  };

  return (
    <Dialog onClose={() => { }} open={open} className={classes.dialog}>
      <Box className={classes.dialogContents}>
        <Box className={clsx(classes.header, classes.mintHeader)} position='relative'>
          <Typography variant='h6' fontWeight={800} fontFamily='Inter'>
            Sign into Chainweaver
          </Typography>
          <IconButton className={classes.closeBtn} onClick={handleClose}>
            <img src={closeIcon} width={14} height={14} alt="" />
          </IconButton>
        </Box>
        <Box>
          <Typography variant='body1' align='center'>
            Please make sure the KDA account provided is controlled by your Chainweaver wallet.
          </Typography>
          {/* <Box className={classes.divider} my={4}></Box> */}
          <Box
            component="form"
            sx={{
              '& > :not(style)': { my: 2, width: '100%' },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-basic"
              label="Insert Your Account"              
              error={accountId !== '' ? !checkKey(accountId) : false}
              variant="outlined"
              onChange={(e) => {
                setAccountId(e.target.value);
              }} />
          </Box>
          <Typography variant='body1' align='center'>
            When submitting a transaction, Chainweaver will show you a preview within the wallet before signing.
          </Typography>
          <Box textAlign='center' mt={4} className={classes.mintBtn}>
            <CustomButton fullWidth variant='greenFill' disabled={!checkKey(accountId)} 
              onClick={()=>handleConnect()} position='relative'>
              Confirm
            </CustomButton>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

export default ChainweaverWalletDialog;
