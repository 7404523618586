import {FC, ReactNode} from 'react';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";

import Button from '@mui/material/Button';

import styles from 'assets/jss/components/buttonStyles';

const useStyles = makeStyles(styles);

interface SxObj {
  marginTop?: string,
  width?: string
  cursor?: string
}

interface Props {
  children?: ReactNode;
  variant: string;
  fullWidth?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  sx?: SxObj;
  position?: string;
  startIcon?: any;
  others?: any
}
const CustomButton: FC<Props> = (props) => {

  const { children, variant, fullWidth, position, disabled, sx, startIcon, onClick, ...others} = props;
  const classes = useStyles();

  const classNames = clsx(
    classes.btn,
    {
      [classes.gradientFill]: variant === 'gradientFill',
      [classes.gradientOutline]: variant === 'gradientOutline',
      [classes.whiteFill]: variant === 'whiteFill',
      [classes.greenFill]: variant === 'greenFill'
    }
  )

  return (
    <Button {...others} variant='contained' disabled={disabled} className={classNames} onClick={onClick} sx={sx} startIcon={startIcon} fullWidth={fullWidth}>
      <span className='btn-text'>{children}</span>
    </Button>
  )
}

export default CustomButton;
