import { FC, ReactNode } from 'react';
import { makeStyles } from "@mui/styles";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from 'assets/jss/components/layoutStyles';
const useStyles = makeStyles(styles);

interface Props {
  children?: ReactNode
}

const Layout: FC<Props> = (props) => {

  const classes = useStyles();

  return (
    <Box style={{ background: "black", overflow: "hidden", maxHeight: '100vh', minWidth: '100vw' }}>
      <Box className={classes.contents} display='flex' flexDirection='column'>
        <Typography variant='h1' fontWeight={800} sx={{ fontSize: "50px", color: "white", textAlign: "center", marginTop: "10px" }}>
          RILEY RANK 5.2
        </Typography>
        <Typography variant='h1' fontWeight={800} sx={{ fontSize: "20px", color: "red", textAlign: "center", marginTop: "10px", marginBottom: "20px" }}>
          BETA          
        </Typography>        
        <Box className={classes.contentsInner}>
          {props.children}
        </Box>
      </Box>
    </Box>
  )
}

export default Layout;
