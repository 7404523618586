import { useEffect, useState, FC } from 'react';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { makeStyles } from "@mui/styles";

import GetZelcoreWalletDialog from 'components/Modals/GetZelcoreWalletDialog';
import ChainweaverWalletDialog from 'components/Modals/ChainweaverWalletDialog';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import closeIcon from 'assets/img/close-green.png';
import xWalletIcon from 'assets/img/x-wallet.png';
import chainweaver from 'assets/img/chainweaverwallet.png';
import zelcoreIcon from 'assets/img/zelcore.png';
import styles from 'assets/jss/components/dialogStyles';

import { useKaddexWalletContext, useAccountContext } from 'contexts';
import { WALLET } from 'constants/wallet';

const useStyles = makeStyles(styles);

interface Props {
  open: boolean;
  handleClose: () => void
}

const ConnectWalletDialog: FC<Props> = (props) => {

  const classes = useStyles();

  const { open, handleClose } = props;
  const { account } = useAccountContext();
  
  // @ts-ignore
  const { initializeKaddexWallet, isInstalled } = useKaddexWalletContext();

  const [openGetZelcoretWallet, setOpenGetZelcoretWallet] = useState<boolean>(false);
  const [openChainweaverWallet, setOpenChainweaverWallet] = useState<boolean>(false);
  const [isConnectingXWallet, setIsConnectingXWallet] = useState<boolean>(false);

  const openWalletModal = (walletName: string) => {
    switch (walletName) {
      case WALLET.KADDEX_WALLET.name:
        if (!isInstalled) {
          toast.warning(`Please install ${WALLET.KADDEX_WALLET.name}`);
        } else {
          setIsConnectingXWallet(true);
          initializeKaddexWallet();
        }
        break;

      case WALLET.ZELCORE.name:
        setOpenGetZelcoretWallet(true);
        handleClose();
        break;
      // return modalContext.openModal({
      //   id: 'ZELCORE',
      //   title: 'connect wallet',
      //   description: 'Zelcore Signing',
      //   onBack: () => modalContext.onBackModal(),
      //   content: <ConnectWalletZelcoreModal />,
      // });        

      case WALLET.CHAINWEAVER.name:
        setOpenChainweaverWallet(true);
        handleClose();
        break;
      // return modalContext.openModal({
      //   id: 'CHIANWEAVER',
      //   title: 'connect wallet',
      //   description: 'Chainweaver',
      //   onBack: () => modalContext.onBackModal(),
      //   content: <ConnectWalletChainweaverModal onClose={() => modalContext.closeModal()} />,
      // });      

      default:
        return <div />;
    }
  };

  useEffect(()=>{
    if (account.account) {
      setIsConnectingXWallet(true);
    } else {
      setIsConnectingXWallet(false);
    }
  }, [account.account]);

  return (
    <>
      <Dialog onClose={() => { }} open={open} className={classes.dialog}>
        <Box className={classes.dialogContents}>
          <Box className={classes.header} position='relative'>
            <Typography variant='h6' fontWeight={800} fontFamily='Inter'>
              Connect your wallet
            </Typography>
            <IconButton className={classes.closeBtn} onClick={handleClose}>
              <img src={closeIcon} width={14} height={14} alt="" />
            </IconButton>
          </Box>
          <Typography variant='body1'>
            To mint with Ethereum, you need to connect MetaMask and one of the two Kadena Wallets, X-Wallet or Zelcore
          </Typography>
          <Box>
            <Button fullWidth className={classes.walletBtn} onClick={() => openWalletModal(WALLET.KADDEX_WALLET.name)}>
              { isConnectingXWallet ? 'Retry' : 'X-Wallet'}
              <Box display='flex'>
                <img src={xWalletIcon} alt="" />
              </Box>
            </Button>
            <Button fullWidth className={clsx(classes.walletBtn, classes.zelcoreBtn)} onClick={() => openWalletModal(WALLET.ZELCORE.name)}>
              Zelcore
              <Box display='flex'>
                <img src={zelcoreIcon} alt="" />
              </Box>
            </Button>
            <Button fullWidth className={classes.walletBtn} onClick={() => openWalletModal(WALLET.CHAINWEAVER.name)}>
              Chainweaver
              <Box display='flex' alignItems='center' justifyContent='center'>
                <img src={chainweaver} alt="" width={30} height={30} />
              </Box>
            </Button>
          </Box>
        </Box>
      </Dialog>

      <GetZelcoreWalletDialog
        open={openGetZelcoretWallet}
        handleClose={() => setOpenGetZelcoretWallet(false)}
      />

      <ChainweaverWalletDialog
        open={openChainweaverWallet}
        handleClose={() => setOpenChainweaverWallet(false)}
      />

    </>
  )
}

export default ConnectWalletDialog;

