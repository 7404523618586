import { Theme } from "@mui/system";

const layoutStyles = ((theme: Theme) => ({
  contents: {
    background: "black",
    position: 'relative' as 'relative',
    width: '100%',   
    height: '100vh',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      inset: '0 0 0 0',
    }
  },
  contentsInner: {
    flex: 'auto',
  }
}));

export default layoutStyles;
