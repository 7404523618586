
import { Theme } from "@mui/system";

const homePageStyles = ((theme: Theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'center',
    zIndex: '1000',
    padding: '5px 50px 25px',
    // padding: '39px 50px',
    boxShadow: theme.palette.mode === 'dark' ? '#000' : '0px 4px 10px rgba(0, 0, 0, 0.15)',
    '@media screen and (max-width: 1350px)': {
      padding: '5px 50px 25px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '25px 22px',
      left: '0',
    }
  },
  searchField: {
    width: '37.75%',
    '& .MuiInputBase-root': {
      width: '100%',
    },
    [theme.breakpoints.down('lg')]: {
      marginBottom: '16px'
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  chain: {
    color: 'white',
    background: '#2B6AD8',
    padding: '10px',
    borderRadius: '40px',
    [theme.breakpoints.down('md')]: {
      display: 'none !important'
    }
  },
  discordBtn: {
    width: 'calc(50% - 14px)',
    '@media screen and (max-width: 1350px)': {
      marginRight: '20px !important',
      width: '180px'
    }
  },
  walletBtn: {
    width: 'calc(50% - 14px)',
    '@media screen and (max-width: 1350px)': {
      width: '170px'
    }
  },
  userBtn: {
    '&.MuiButtonBase-root': {
      width: '56px',
      height: '56px',
      padding: '0',
      borderRadius: '6px',
      background: 'linear-gradient(180deg, #2B6AD8 0%, #8B41AB 100%)',
      minWidth: 'unset',
      boxShadow: '0px 4px 10px rgba(1, 222, 142, 0.25)'
    },
    [theme.breakpoints.down('lg')]: {     
      '&.MuiButtonBase-root': {
        width: '31px',
        height: '36px'
      }
    }
  },
  badge: {
    cursor: 'pointer'
  },
  notification: {
    marginLeft: '42px',
    marginRight: '42px',
    [theme.breakpoints.down('lg')]: {
      marginLeft: '30px',
      marginRight: '30px'
    }
  },
  btns: {
    width: '38.75%',
    '@media screen and (max-width: 1350px)': {
      width: 'auto'
    }
  },
  calcBtn: {
    width: "180px",
    height: "55px",
    backgroundColor: 'green !important',
    padding: '15px',
    borderRadius: "4px",
    color: "white !important",
    '&.Mui-disabled': {   
      backgroundColor: 'green !important',
      opacity: 0.7,
      pointerEvents: 'visible !important',
      cursor: 'not-allowed !important',
    }
  },
  rightIcons: {
    [theme.breakpoints.down('md')]: {
      display: 'none !important'
    }
  },
  menuToggleBtn: {
    [theme.breakpoints.up('md')]: {
      display: 'none !important'
    }
  },
  hamburgerBtn: {
    [theme.breakpoints.up('md')]: {
      display: 'none !important'
    }
  },
  logo: {
    [theme.breakpoints.up('md')]: {
      display: 'none !important'
    }
  },
  bannerSection: {   
    display: 'flex',
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: '8px',
    margin: 'auto'
  },
  tab: {
    color: 'white',
    marginTop: '25px',
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    border: '1px solid gray',
    borderRadius: '30px',
    padding: '4px',
    width: 'fit-content'
  },
  pay: {
    padding: '8px',
    width: '100px',
    textAlign: 'center',
    // background: "#2B6AD8 ",
    borderRadius: '30px',
    '&:hover': {
      background: "#2B6AD8",
      cursor: 'pointer'
    },
    '&.active': {
      background: "#2B6AD8",
      cursor: 'pointer'
    }
  },
  bannerSectionInner: {
    padding: '24px 40px',
    minHeight: '275px',
    boxSizing: 'border-box' as 'border-box',
    '& h3': {
      fontSize: '32px',
      lineHeight: '38px',
      color: '#fff',
      marginBottom: '14px'
    },
    '& h5': {
      fontSize: '15px',
      lineHeight: '20px',
      color: '#fff',
      marginBottom: '14px'
    },
    '& p': {
      fontSize: '13px',
      lineHeight: '16px',
      color: '#fff',
      marginBottom: '16px'
    },
    '& .MuiButtonBase-root': {
      width: '160px',
      fontSize: '16px',
      lineHeight: '20px',
      paddingLeft: '0',
      paddingRight: '0',
      fontWeight: '600',
      borderRadius: '8px',
      height: '50px'
    },
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      paddingBottom: '0',
      backgroundSize: 'cover',
      backgroundPosition: 'center center'
    },
    [theme.breakpoints.down('sm')]: {    
      padding: '24px 26px 0',
      '& h3': {
        fontSize: '20px',
        lineHeight: '24px',
        marginBottom: '12px'
      },
      '& p': {
        fontSize: '11px',
        lineHeight: '13.7px'
      },
      '& .MuiButtonBase-root': {
        width: '130px',
        fontSize: '12px',
        lineHeight: '15px',
        height: '34px'
      },
    }
  },
  bannerTextCell: {
    width: '68.6%',
    zIndex: '2',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  heroImg: {   
    right: '-28px',
    bottom: '0',
    width: '466px',
    [theme.breakpoints.down('md')]: {
      position: 'unset !important',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  benefitsSection: {
    marginTop: '20px',
    '& h4': {
      fontSize: '22px',
      lineHeight: '27px',
      marginBottom: '20px',
      color: theme.palette.mode === 'dark' ? '#fff' : '#2B6AD8',
    }
  },
  benefitsList: {
    backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff',
    padding: '25px 24px',
    borderRadius: '8px',
    boxShadow: theme.palette.mode === 'dark' ? '6.96317px 10.4447px 43.5198px rgba(0, 0, 0, 0.4)' : 'none',
    [theme.breakpoints.down('sm')]: {
      padding: '16px'
    }
  },
  selectBox: {
    width: '180px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '0px'
    }
  },
  pricingNote: {   
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', 
    flexDirection: 'column',
    margin: 'auto',
    backgroundColor: '#01DE8E',
    width: 'fit-content',
    borderRadius: '20px',
    padding: '18px',
    textAlign: 'center',    
  },
  priceContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  priceBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px'
  },
  priceHead: {
    margin: '10px 0',
    fontSize: '30px',
    color: 'red',
    fontWeight: 'bold'
  },
  priceTitle: {
    margin: '4px 0',
    fontSize: '20px',
    color: 'white'
  }
}));

export default homePageStyles;
