import { useContext } from 'react';
import { KaddexWalletContext } from './KaddexWalletContext';
import { AccountContext } from './AccountContext';
import { WalletContext } from './WalletContext';

export function useKaddexWalletContext() {
  return useContext(KaddexWalletContext);
}

export function useAccountContext() {
  return useContext(AccountContext);
}

export function useWalletContext() {
  return useContext(WalletContext);
}


