// @ts-ignore
import Pact from 'pact-lang-api';
import { handleError } from './functions';
import { NETWORK, CHAIN_ID, GAS_PRICE, creationTime, NETWORK_VERSION, NETWORKID } from 'constants/contextConstants';

export const pactFetchLocal = async (pactCode: string) => {
  let data = await Pact.fetch.local(
    {
      pactCode,
      meta: Pact.lang.mkMeta('', CHAIN_ID, GAS_PRICE, 150000, creationTime(), 600),
    },
    NETWORK
  );  
  if (data.result.status === 'success') {
    return data.result.data;
  } else if (data.result.error.message) {
    const errorMessage = handleError(data);
    return { errorMessage: data.result.error.message || errorMessage };
  } else {
    return handleError(data);
  }
};

export const getPresaleMintPrice = async () => {
  try {
    let data = await pactFetchLocal("(free.fsyc-pre-sale.get-sale-price)");
    if (data) {
      return data;
    }
  } catch (e) {
    return handleError(e);
  }
}

export const getPresaleStartTime = async () => {
  try {
      let data = await pactFetchLocal("(free.fsyc-pre-sale.get-whitelist-time)");
      if (data) {
          return data.time;
      }
  } catch (e) {
      return handleError(e);
  }
}

export const getPubilcSaleStartTime = async () => {
  try {
      let data = await pactFetchLocal("(free.fsyc-pre-sale.get-start-time)");
      if (data) {
          return data.time;
      }
  } catch (e) {
      return handleError(e);
  }
}

export const getPubilcSaleEndTime = async () => {
  try {
      let data = await pactFetchLocal("(free.fsyc-pre-sale.get-end-time)");
      if (data) {
          return data.time;
      }
  } catch (e) {
      return handleError(e);
  }
}

export const getSaleSupply = async () => {
  try {
      let data = await pactFetchLocal("(free.fsyc-pre-sale.get-sale-supply)");
      if (data) {
          return data;
      }
  } catch (e) {
      return handleError(e);
  }
}

export const getPerUserMaxMintCount = async () => {
  try {
      let data = await pactFetchLocal("(free.fsyc-pre-sale.get-fsyc-per-user)");
      if (data) {
          return data;
      }
  } catch (e) {
      return handleError(e);
  }
}

export const getTotalMintedCount = async () => {
  try {
      let data = await pactFetchLocal("(free.fsyc-pre-sale.get-total-fsyc-reserved)");
      if (data) {
          return data;
      }
  } catch (e) {
      return handleError(e);
  }
}

export const getAccountTotalMintedCount = async (accountName: string) => {
  try {
      let data = await pactFetchLocal(`(free.fsyc-pre-sale.get-fsyc-reserved ${JSON.stringify(accountName)})`);
      if (data >= 0) {
          return data;
      }
  } catch (e) {
      return handleError(e);
  }
}

export const accountCheckWL = async (accountName: string) => {
  try {
      let data = await pactFetchLocal(`(free.fsyc-pre-sale.check-whitelist ${JSON.stringify(accountName)})`);
      if (data) {
          return data;
      }
  } catch (e) {
      return handleError(e);
  }
}

export const getTotaNFTHolders = async () => {
  try {
      let data = await pactFetchLocal("(free.fsyc-pre-sale.get-total-reservee)");      
      if (data) {
          return data;
      }
  } catch (e) {
      return handleError(e);
  }
}

export const getAccountBalances = async (accountName: string) => {
  let balancesArray = [];
  for (let i = 0; i < 20; i++) {
    let data = await Pact.fetch.local(
      {
        pactCode: `(coin.get-balance ${JSON.stringify(accountName)})`,
        meta: Pact.lang.mkMeta('', CHAIN_ID, GAS_PRICE, 3000, creationTime(), 600),
      },
      `${process.env.REACT_APP_KDA_NETWORK}/chainweb/${NETWORK_VERSION}/${NETWORKID}/chain/${i}/pact`
    );
    if (data.result.status === 'success') {
      balancesArray.push(data.result.data);
    } else {
      balancesArray.push(0);
    }
  }

  return balancesArray;
}

