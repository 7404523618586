import { useState, FC } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CustomButton from 'components/CustomButton';
import closeIcon from 'assets/img/close-green.png';
import styles from 'assets/jss/components/dialogStyles';
import { WALLET } from 'constants/wallet';
import { useAccountContext } from 'contexts';
import { reduceToken } from 'utils/reduceToken';
import { checkKey } from 'utils/functions';

const useStyles = makeStyles(styles);

interface Props {
  open: boolean;
  handleClose: () => void;
  accounts: string[]
}

const ZelcoreWalletSelectDialog: FC<Props> = (props) => {

  const { open, handleClose, accounts } = props;
  const classes = useStyles();

  const { setVerifiedAccount } = useAccountContext();

  const [selectedAccount, setSelectedAccount] = useState('');

  const handleDropdownChange = (value: string) => {
    if (checkKey(value)) {
      setSelectedAccount(value);
    } else {
      toast.warning('You chose Public key. Select valid account');
    }
  };

  const handleConnect = async () => {
    if (selectedAccount) {
      await setVerifiedAccount(selectedAccount, WALLET.ZELCORE);
      handleClose();
    } else {
      toast.warning('Select your account');
    }
  };

  return (
    <Dialog onClose={() => { }} open={open} className={classes.dialog}>
      <Box className={classes.dialogContents}>
        <Box className={clsx(classes.header, classes.mintHeader)} position='relative'>
          <Typography variant='h6' fontWeight={800} fontFamily='Inter'>
            Get Zelcore accounts
          </Typography>
          <IconButton className={classes.closeBtn} onClick={handleClose}>
            <img src={closeIcon} width={14} height={14} alt="" />
          </IconButton>
        </Box>
        <Box>
          <Typography variant='body1' align='center'>
            Select Account
          </Typography>
          <Typography variant='body1' align='center'>
            Choose wallet address you intend to use
          </Typography>
          <Box sx={{ minWidth: 120, my: 1 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select account</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedAccount}
                label="Select account"
                onChange={(e) => handleDropdownChange(e.target.value)}
              >
                {
                  accounts && accounts.map((item , index) => (
                    <MenuItem key={index} value={item}>{reduceToken(item)}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>            
          </Box>
          <Box textAlign='center' mt={4} className={classes.mintBtn}>
            <CustomButton 
              fullWidth
              variant='greenFill' 
              disabled={selectedAccount? false: true}
              onClick={() => handleConnect()} 
              position='relative'
            >
              Confirm
            </CustomButton>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

export default ZelcoreWalletSelectDialog;
