// @ts-nocheck
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//firebase
import { db } from 'utils/firebase';

// Constants
import {
  NETWORK,
  CHAIN_ID,
  GAS_PRICE,
  GAS_LIMIT,
  NETWORKID,
  RILEY_WALLET,
  COLLECTION_NAME,
  creationTime
} from 'constants/contextConstants';

// Pact
import Pact from 'pact-lang-api';

// MUI
import { makeStyles } from "@mui/styles";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// Assets
import styles from 'assets/jss/views/homePageStyles';

// Utils
import { listen, mkReq, parseRes } from 'utils/functions';
import { reduceToken } from 'utils/reduceToken';

// Components
import ConnectWalletModal from 'components/Modals/ConnectWalletDialog';
import DisconnectWalletDialog from 'components/Modals/DisconnectWalletDialog';
import AccountDetailDialog from 'components/Modals/AccountDetailDialog';
import CustomButton from 'components/CustomButton';

// Contexts
import { useAccountContext, useKaddexWalletContext, useWalletContext } from 'contexts';

const useStyles = makeStyles(styles);


const ELEMENTS = [
  {
    value: "0",
    title: "Select Element"
  },
  {
    value: "96",
    title: "Spirit"
  },
  {
    value: "-63",
    title: "Acid"
  },
  {
    value: "-14",
    title: "Wind"
  },
  {
    value: "119",
    title: "Psycho"
  },
  {
    value: "61",
    title: "Thunder"
  },
  {
    value: "25",
    title: "Sun"
  },
  {
    value: "-47",
    title: "Ice"
  },
  {
    value: "-87",
    title: "Fire"
  },
  {
    value: "-38",
    title: "Dark"
  },
  {
    value: "107",
    title: "Undead"
  },
  {
    value: "-158",
    title: "Earth"
  },
  {
    value: "16",
    title: "Water"
  }
]

const RESISTANCES = [
  {
    value: "0",
    title: "Select Resistance"
  },
  {
    value: "286",
    title: "Spirit"
  },
  {
    value: "260",
    title: "Acid"
  },
  {
    value: "270",
    title: "Wind"
  },
  {
    value: "277",
    title: "Psycho"
  },
  {
    value: "271",
    title: "Thunder"
  },
  {
    value: "258",
    title: "Sun"
  },
  {
    value: "274",
    title: "Ice"
  },
  {
    value: "274.0",
    title: "Fire"
  },
  {
    value: "155",
    title: "Dark"
  },
  {
    value: "167",
    title: "Undead"
  },
  {
    value: "277.0",
    title: "Earth"
  },
  {
    value: "263",
    title: "Water"
  }
]

const WEAKNESS = [
  {
    value: "0",
    title: "Select Weakness"
  },
  {
    value: "-286",
    title: "Spirit"
  },
  {
    value: "-260",
    title: "Acid"
  },
  {
    value: "-270",
    title: "Wind"
  },
  {
    value: "-277",
    title: "Psycho"
  },
  {
    value: "-271",
    title: "Thunder"
  },
  {
    value: "-258",
    title: "Sun"
  },
  {
    value: "-274",
    title: "Ice"
  },
  {
    value: "-274.0",
    title: "Fire"
  },
  {
    value: "-155",
    title: "Dark"
  },
  {
    value: "-167",
    title: "Undead"
  },
  {
    value: "-277.0",
    title: "Earth"
  },
  {
    value: "-263",
    title: "Water"
  }
]

const SPELLS = [
  {
    value: "0",
    title: "Select Spell"
  },
  {
    value: "-286",
    title: "Spirit"
  },
  {
    value: "-260",
    title: "Acid"
  },
  {
    value: "-270",
    title: "Wind"
  },
  {
    value: "-277",
    title: "Psycho"
  }
]

const HomePage = () => {
  const { account } = useAccountContext();
  const { wallet } = useWalletContext();

  // @ts-ignore
  const { isConnected: isKaddexWalletConnected, requestSign: kaddexWalletRequestSign } = useKaddexWalletContext();

  const classes = useStyles();

  const [openConnectWallet, setOpenConnectWallet] = useState<boolean>(false);
  const [openDisconnectWalletDialog, setOpenDisconnectWalletDialog] = useState<boolean>(false);
  const [openAccountDetailDialog, setOpenAccountDetailDialog] = useState<boolean>(false);

  const [element, setElement] = useState<string>('0');
  const [resistance, setResistance] = useState<string>('0');
  const [weakness, setWeakness] = useState<string>('0');
  const [spell, setSpell] = useState<string>('0');
  const [number, setNumber] = useState<number>(0);
  const [spellArray, setSpellArray] = useState<Array<{}>>([]);
  const [weaknessArray, setWeaknessArray] = useState<Array<{}>>([]);
  const [calculateResult, setCalculateResult] = useState<string>('');
  const [isPay, setIsPay] = useState<boolean>(false);
  const [paidRemainCnt, setPaidRemainCnt] = useState<number>(0);
  const [purchaseAmount, setPurchaseAmount] = useState<number>(1);
  const [isPurchasing, setIsPurchasing] = useState<boolean>(false);

  const pay = async () => {    
    let accountName = account?.account;
    if (accountName == null) {
      toast.info('Please connect wallet');
      return;
    }

    let checkWizaTokenBalanceStatus = await checkWizaTokenBalance(accountName);
    if (checkWizaTokenBalanceStatus < purchaseAmount ) {
      toast.info('Please make sure you have engouh Wiza token in your wallet.');
      return;
    }

    setIsPurchasing(true);

    try {
      const pactCode = `(free.wiza.transfer ${JSON.stringify(accountName)} ${JSON.stringify(RILEY_WALLET)} ${purchaseAmount.toFixed(2)})`;
      let cmd = null;

      if (isKaddexWalletConnected) {
        const XWalletRequest = {
          networkId: NETWORKID,
          signingCmd: {
            sender: accountName,
            chainId: CHAIN_ID,
            gasPrice: GAS_PRICE,
            gasLimit: GAS_LIMIT,
            ttl: 600,
            caps: [
              Pact.lang.mkCap("Gas Capability", "Agreement to Pay Gas", "coin.GAS", []),
              Pact.lang.mkCap("Transfer Capability", "Agreement to Transfer 0.1 KDA to Riley for 1 STAFF", "free.wiza.TRANSFER", [accountName, RILEY_WALLET, parseFloat(purchaseAmount)])
            ],
            pactCode: pactCode,
            envData: {},
            networkId: NETWORKID,
            // @ts-ignore
            signingPubKey: account.guard.keys[0],
          } //alert to sign tx

        };

        cmd = await kaddexWalletRequestSign(XWalletRequest);

      } else {
        const signCmd = {
          pactCode: pactCode,
          caps: [
            Pact.lang.mkCap("Gas Capability", "Agreement to Pay Gas", "coin.GAS", []),
            Pact.lang.mkCap("Transfer Capability", "Agreement to Transfer 0.1 KDA to Riley for 1 STAFF", "free.wiza.TRANSFER", [accountName, RILEY_WALLET, parseFloat(purchaseAmount)])
          ],
          sender: accountName,
          gasLimit: GAS_LIMIT,
          gasPrice: GAS_PRICE,
          chainId: CHAIN_ID,
          ttl: 600,
          envData: {},
          // @ts-ignore
          signingPubKey: account.guard.keys[0],
          networkId: NETWORKID
        }; //alert to sign tx

        cmd = await Pact.wallet.sign(signCmd);
      }

      try {
        let res = null;
        if (isKaddexWalletConnected) {
          if (cmd.status === "success") {
            let data = await fetch(`${NETWORK}/api/v1/local`, mkReq(cmd.signedCmd));
            const testResult = await parseRes(data);
            if (testResult.result.status === 'success') {
              res = await Pact.wallet.sendSigned(cmd.signedCmd, NETWORK);
            } else {
              toast.warning(testResult.result.error.message)
              setIsPurchasing(false);
              return;
            }
          } else {
            toast.warning('That request has been rejected')
            setIsPurchasing(false);
            return;
          }
        } else {
          let data = await fetch(`${NETWORK}/api/v1/local`, mkReq(cmd));
          const testResult = await parseRes(data);
          if (testResult.result.status === 'success') {
            res = await Pact.wallet.sendSigned(cmd, NETWORK);
          } else {
            toast.warning(testResult.result.error.message)
            setIsPurchasing(false);
            return;
          }
        }

        const requestResult = await listen(res.requestKeys[0]);
        if (res.requestKeys[0] === '' || res.requestKeys[0] === undefined) {
          toast.warning('Error occured')
        } else {
          setIsPurchasing(false);
          if (requestResult.result.status === 'success') {
            // toast.success('Success! Check it out in the block explorer');
            await addRemainCnt(number);
            await getRemainCnt();
          } else {
            toast.success('Failed! Check it out in the block explorer');
          }
        }
      } catch (error) {
        console.log('error', error)
        toast.success('That request has been rejected');
        setIsPurchasing(false);
      }
    } catch (error) {
      setIsPurchasing(false);
      if (!isKaddexWalletConnected) {
        toast.info(`Please open ${wallet.name}`);
      }
    }
  }

  const checkWizaTokenBalance = async (wallet: string) => {
    let data = await Pact.fetch.local(
      {
        pactCode: `(free.wiza.get-balance ${JSON.stringify(wallet)})`,
        meta: Pact.lang.mkMeta('', CHAIN_ID, GAS_PRICE, 3000, creationTime(), 600),
      },
      NETWORK
    );
    
    if (data.result.status === 'success') {
      return data.result.data;
    } else {
      return 0;
    }
  }

  const calculate = () => {
    if (paidRemainCnt == 0) {
      toast.info('You need to pay to calculate')
      return;
    }
    if (account.account && !!element && !!resistance && !!weakness && paidRemainCnt > 0) {
      const value1 = parseInt(element);
      const value2 = parseInt(resistance);
      const value3 = parseInt(weakness);
      const value4 = parseInt(spell);

      const sum = (value1 + value2 + value3 + value4) / 10;

      setCalculateResult(sum);
      decreaseOneRemainCnt();
    } else {
      console.log('empty')
    }
  }

  const onChangeElement = (selectedValue) => {
    let filterdSpellArray = [];

    if (selectedValue === '') return;

    if (selectedValue === "0") {
      setSpell('');
    }

    //spirit element
    if (selectedValue === "96") {
      filterdSpellArray = [
        {
          title: "None",
          value: "0"
        },
        {
          title: "Wail of spirits",
          value: "46.71"
        },
        {
          title: "Cursed Whispers",
          value: "34.964"
        },
        {
          title: "Mental Prison",
          value: "66.69"
        },
        {
          title: "Summon Spirit",
          value: "46.04"
        },
        {
          title: "Possession",
          value: "69.38"
        },
        {
          title: "Phantasmal Force",
          value: "39.28"
        }
      ];
    }

    // sun element
    else if (selectedValue === "25") {
      filterdSpellArray = [
        {
          title: "None",
          value: "0"
        },
        {
          title: "Sunbeam",
          value: "60.64"
        },
        {
          title: "Dawn",
          value: "76.69"
        },
        {
          title: "Solar Faith",
          value: "35.898"
        },
        {
          title: "Sacred Flame",
          value: "42.84"
        },
        {
          title: "Light Shield",
          value: "50.0475"
        },
        {
          title: "Divine Light",
          value: "54.92"
        }
      ];
    }

    //acid element
    else if (selectedValue === "-63") {
      filterdSpellArray = [
        {
          title: "None",
          value: "0"
        },
        {
          title: "Cloudkill",
          value: "64.16"
        },
        {
          title: "Acid Beam",
          value: "42.84"
        },
        {
          title: "Acid Arrow",
          value: "35.098"
        },
        {
          title: "Poison Barrage",
          value: "31.36"
        },
        {
          title: "Infestation",
          value: "59.786"
        },
        {
          title: "Corrosion",
          value: "54.92"
        }
      ];
    }

    //wind element
    else if (selectedValue === "-14") {
      filterdSpellArray = [
        {
          title: "None",
          value: "0"
        },
        {
          title: "Wave of Nature",
          value: "56.69"
        },
        {
          title: "Tornado",
          value: "44.04"
        },
        {
          title: "Wind Strike",
          value: "39.37"
        },
        {
          title: "Wind Rupture",
          value: "30.9945"
        },
        {
          title: "Wind Barrier",
          value: "52.05"
        },
        {
          title: "Whirl",
          value: "56.72"
        }
      ];
    }

    //psycho element
    else if (selectedValue === "119") {
      filterdSpellArray = [
        {
          title: "None",
          value: "0"
        },
        {
          title: "Fear",
          value: "34.964"
        },
        {
          title: "Nightmare",
          value: "44.04"
        },
        {
          title: "command",
          value: "66.69"
        },
        {
          title: "madness",
          value: "46.04"
        },
        {
          title: "Deadly Illusion",
          value: "79.37"
        },

        {
          title: "Maddening Darkness",
          value: "54.446"
        }
      ];
    }

    //Thunder element
    else if (selectedValue === "61") {
      filterdSpellArray = [
        {
          title: "None",
          value: "0"
        },
        {
          title: "Lightning",
          value: "39.37"
        },
        {
          title: "Storm",
          value: "41.37"
        },
        {
          title: "Thunder",
          value: "57.02"
        },
        {
          title: "Thunder Spike",
          value: "46.69"
        },
        {
          title: "shock",
          value: "64.71"
        },
        {
          title: "Shatter",
          value: "55.248"
        }
      ];
    }

    //ice element
    else if (selectedValue === "-47") {
      filterdSpellArray = [
        {
          title: "None",
          value: "0"
        },
        {
          title: "Ice Storm",
          value: "49.35"
        },
        {
          title: "Frost bite",
          value: "28.558"
        },
        {
          title: "Ice Spear",
          value: "44.04"
        },
        {
          title: "Frost Missiles",
          value: "42.04"
        },
        {
          title: "avalanche",
          value: "86.71"
        },
        {
          title: "ice shield",
          value: "51.516"
        }
      ];
    }

    //fire element
    else if (selectedValue === "-87") {
      filterdSpellArray = [
        {
          title: "None",
          value: "0"
        },
        {
          title: "Hellfire Strike",
          value: "42.04"
        },
        {
          title: "Fireball",
          value: "44.04"
        },
        {
          title: "Fiery Smoke",
          value: "42.02"
        },
        {
          title: "Ignite",
          value: "30.161"
        },
        {
          title: "Hellish Flame",
          value: "57.75"
        },
        {
          title: "Dragon's Breath",
          value: "76.71"
        }
      ];
    }

    //dark element
    else if (selectedValue === "-38") {
      filterdSpellArray = [
        {
          title: "None",
          value: "0"
        },
        {
          title: "Bane",
          value: "26.69"
        },
        {
          title: "Unholy Nova",
          value: "44.04"
        },
        {
          title: "Vindicate",
          value: "42.04"
        },
        {
          title: "Hallucination",
          value: "52.02"
        },
        {
          title: "hex",
          value: "57.918"
        },
        {
          title: "Blight",
          value: "57.918"
        }
      ];
    }

    //undead element
    else if (selectedValue === "107") {
      filterdSpellArray = [
        {
          title: "None",
          value: "0"
        },
        {
          title: "Whiter",
          value: "64.69"
        },
        {
          title: "Circle of Death",
          value: "46.04"
        },
        {
          title: "Decay",
          value: "37.898"
        },
        {
          title: "Summon Undead",
          value: "44.04"
        },
        {
          title: "Finger of Death",
          value: "74.04"
        },
        {
          title: "Danse Macabre",
          value: "54.314"
        }
      ];
    }

    //earth element
    else if (selectedValue === "-158") {
      filterdSpellArray = [
        {
          title: "None",
          value: "0"
        },
        {
          title: "Flesh to Stone",
          value: "42.04"
        },
        {
          title: "Erupting Earth",
          value: "44.04"
        },
        {
          title: "Earth Tremor",
          value: "33.899"
        },
        {
          title: "Earthquake",
          value: "64.69"
        },
        {
          title: "Rock Shield",
          value: "51.516"
        },
        {
          title: "Meteors",
          value: "56.72"
        }
      ];
    }

    //water element
    else if (selectedValue === "16") {
      filterdSpellArray = [
        {
          title: "None",
          value: "0"
        },
        {
          title: "Watery Sphere",
          value: "66.69"
        },
        {
          title: "Tsunami",
          value: "44.04"
        },
        {
          title: "Maelstrom",
          value: "46.04"
        },
        {
          title: "Drown",
          value: "35.898"
        },
        {
          title: "Flood",
          value: "79.37"
        },
        {
          title: "Water Barrier",
          value: "50.0475"
        }
      ];
    }

    setElement(selectedValue);
    setSpellArray(filterdSpellArray);
  }

  const onChangeResistance = (selectedValue) => {
    let filterdWeaknessArray = [];
    if (selectedValue === '') return;

    if (selectedValue === "0") {
      setResistance('');
    }

    //sun
    if (selectedValue === "258") {
      filterdWeaknessArray = [
        {
          title: "Fire",
          value: "-274"
        },
        {
          title: "Water",
          value: "-263"
        }
      ];
    }

    //spirit
    else if (selectedValue === "286") {
      filterdWeaknessArray = [
        {
          title: "Sun",
          value: "-258"
        },
        {
          title: "dark",
          value: "-155"
        }
      ];
    }

    //earth
    else if (selectedValue === "277.0") {
      filterdWeaknessArray = [
        {
          title: "Psycho",
          value: "-277"
        },
        {
          title: "Acid",
          value: "-260"
        }
      ];
    }

    //psycho
    else if (selectedValue === "277") {
      filterdWeaknessArray = [
        {
          title: "undead",
          value: "-167"
        },
        {
          title: "fire",
          value: "-274"
        }
      ];
    }

    //wind
    else if (selectedValue === "270") {
      filterdWeaknessArray = [
        {
          title: "Thunder",
          value: "-271"
        },
        {
          title: "Psycho",
          value: "-277"
        }
      ];
    }

    //acid
    else if (selectedValue === "260") {
      filterdWeaknessArray = [
        {
          title: "Spirit",
          value: "-286"
        },
        {
          title: "Sun",
          value: "-258"
        }
      ];
    }

    //ice
    else if (selectedValue === "274") {
      filterdWeaknessArray = [
        {
          title: "wind",
          value: "-270"
        }
      ];
    }

    //fire
    else if (selectedValue === "274.0") {
      filterdWeaknessArray = [
        {
          title: "Acid",
          value: "-260"
        },
        {
          title: "spirit",
          value: "-286"
        }
      ];
    }

    //dark
    else if (selectedValue === "155") {
      filterdWeaknessArray = [
        {
          title: "Ice",
          value: "-274"
        },
        {
          title: "Thunder",
          value: "-271"
        }
      ];
    }

    //thunder
    else if (selectedValue === "271") {
      filterdWeaknessArray = [
        {
          title: "Dark",
          value: "-155"
        },
        {
          title: "Undead",
          value: "-167"
        }
      ];
    }

    setResistance(selectedValue);
    setWeaknessArray(filterdWeaknessArray);
  }

  const onChangeWeakness = (value) => {
    if (value === '') return;

    setWeakness(value);
  }

  const onChangeSpell = (value) => {
    if (value === '') return;

    setSpell(value);
  }

  const calcNumber = (number) => {
    setNumber(number);

    if (number === 1) {
      setPurchaseAmount(10);
    } else if (number === 10) {
      setPurchaseAmount(90);
    } else if (number === 20) {
      setPurchaseAmount(170);
    }
  }

  const addRemainCnt = async (cnt: number) => {
    if (account.account === '' || cnt === '') return;
    const querySnapshot = await db.collection(COLLECTION_NAME).where('wallet', '==', account.account).get();

    if (querySnapshot.empty) {
      const data = {
        wallet: account.account,
        remainCnt: cnt
      };
      db.collection(COLLECTION_NAME).add(data).then(() => {
        toast.success('You paid successfully!');
      }).catch((error) => {
        console.error('Error adding data: ', error);
      });
    } else {
      querySnapshot.forEach((doc) => {
        let oldCnt = doc.data().remainCnt;
        doc.ref.update({ ['remainCnt']: oldCnt + cnt });
      });
    }
  };

  const decreaseOneRemainCnt = async () => {
    if (account.account === '') return;

    const querySnapshot = await db.collection(COLLECTION_NAME).where('wallet', '==', account.account).get();
    if (querySnapshot.empty) {
      console.log('wallet not found')
    } else {
      querySnapshot.forEach((doc) => {
        let oldCnt = doc.data().remainCnt;
        if (oldCnt > 0) {
          doc.ref.update({ ['remainCnt']: oldCnt - 1 });
          setPaidRemainCnt(oldCnt - 1);
        }
      });
    }
  }

  const getRemainCnt = async () => {
    const querySnapshot = await db.collection(COLLECTION_NAME).where('wallet', '==', account.account).get();

    if (querySnapshot.empty) {
      setPaidRemainCnt(0);
    } else {
      querySnapshot.forEach((doc) => {
        let remainCnt = doc.data().remainCnt;
        if (remainCnt > 0) {
          setPaidRemainCnt(remainCnt);
        }
      });
    }
  }

  useEffect(() => {
    setSpellArray(SPELLS);
    setWeaknessArray(WEAKNESS);
  }, [])

  useEffect(() => {
    if (account.account) {
      setOpenConnectWallet(false);
    }
  }, [account]);

  useEffect(() => {
    if (account.account) {
      getRemainCnt();      
    }
  }, [account])

  return (
    <>
      {/* start header */}
      <Box className={classes.header} flexWrap='wrap'>
        <Box display='flex' className={classes.btns} justifyContent='space-between'>
          <Box display='flex' justifyContent="center" margin='auto'>
            <Box display='flex' style={{ width: "150px", marginLeft: '20px' }}>
              <CustomButton
                fullWidth
                variant='gradientFill'
                sx={{ width: '300px' }}
              >
                Remains: {paidRemainCnt}
              </CustomButton>
            </Box>
            <Box display='flex' style={{ width: "150px", marginLeft: '20px' }}>
              {account.account &&
                <CustomButton
                  fullWidth
                  variant='gradientFill'
                  onClick={() => {
                    setOpenAccountDetailDialog(true)
                  }}
                >
                  {reduceToken(account.account)}
                </CustomButton>
              }
              {!account.account &&
                <CustomButton
                  fullWidth
                  variant='gradientFill'
                  onClick={() => { setOpenConnectWallet(true) }}
                >
                  Connect Wallet
                </CustomButton>
              }
            </Box>
          </Box>
        </Box>
      </Box>
      {/* end header */}

      {/* start pricing notes */}
      <Box className={classes.pricingNote}>
        <p className={classes.priceHead}>
          Calculation Prices
        </p>
        <Box className={classes.priceContainer}>
          <Box className={classes.priceBox}>
            <h1 className={classes.priceTitle}>1 calculation:</h1>
            <h1 className={classes.priceTitle}>10 Wiza</h1>
          </Box>
          <Box className={classes.priceBox}>
            <h1 className={classes.priceTitle}>10 calculations:</h1>
            <h1 className={classes.priceTitle}>90 Wiza</h1>
          </Box>
          <Box className={classes.priceBox}>
            <h1 className={classes.priceTitle}>20 calculations:</h1>
            <h1 className={classes.priceTitle}>170 Wiza</h1>
          </Box>
        </Box>
      </Box>
      {/* end pricing notes */}

      {/* start content */}
      <Box className={classes.tab}>
        <Box className={classes.pay} style={{ background: isPay ? "" : "#2B6AD8" }} onClick={() => { setIsPay(false) }}>
          Calculate
        </Box>
        <Box className={classes.pay} style={{ marginLeft: '8px', background: isPay ? "#2B6AD8" : "" }} onClick={() => { setIsPay(true) }}>
          Pay
        </Box>
      </Box>
      {
        calculateResult ? (
          <Typography variant='h6' fontWeight={800} sx={{ color: "white", textAlign: "center", marginTop: "20px" }}>
            Riley Rank (Higher is better): {calculateResult}
          </Typography>
        ) : null
      }

      <Box className={classes.bannerSection} sx={{ marginTop: "20px" }}>
        {
          !isPay ? (
            <>
              <Box position='relative' display='flex' mx='auto' px='20px' flexWrap='wrap' gap='12px'>
                <Box className={classes.selectBox}>
                  <FormControl fullWidth>
                    <InputLabel>Element</InputLabel>
                    <Select
                      value={element}
                      label="Select Element"
                      onChange={(e) => onChangeElement(e.target.value)}
                    >
                      {
                        ELEMENTS.map((item, idx) => (
                          <MenuItem key={idx} value={item.value}>{item.title}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Box>
                <Box className={classes.selectBox}>
                  <FormControl fullWidth>
                    <InputLabel>Resistance</InputLabel>
                    <Select
                      value={resistance}
                      label="Select Resistance"
                      onChange={(e) => onChangeResistance(e.target.value)}
                    >
                      {
                        RESISTANCES.map((item, idx) => (
                          <MenuItem key={idx} value={item.value}>{item.title}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Box>
                <Box className={classes.selectBox}>
                  <FormControl fullWidth>
                    <InputLabel>Weakness</InputLabel>
                    <Select
                      value={weakness}
                      label="Select Weakness"
                      onChange={(e) => onChangeWeakness(e.target.value)}
                    >

                      {
                        weaknessArray.map((item, idx) => (
                          <MenuItem key={idx} value={item.value}>{item.title}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Box>
                <Box className={classes.selectBox}>
                  <FormControl fullWidth>
                    <InputLabel>Spell</InputLabel>
                    <Select
                      value={spell}
                      label="Select Spell"
                      onChange={(e) => onChangeSpell(e.target.value)}
                    >
                      {
                        spellArray.map((item, idx) => (
                          <MenuItem key={idx} value={item.value}>{item.title}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Box position='relative' display='flex' justifyContent='center' mt='20px'>
                <Button className={classes.calcBtn}
                  disabled={(element == '0' || resistance == '0' || weakness == '0') ? true : false}
                  onClick={() => calculate()}
                >
                  Calculate
                </Button>
              </Box>
              <Typography variant='h6' fontWeight={800} sx={{ color: "white", textAlign: "center", marginTop: "20px" }}>
                (Riley Rank Parameters: -24.4 : 32.9)
              </Typography>
            </>
          ) : (
            <>
              <Box position='relative' display='flex' mx='auto' my='20px'>
                <Box sx={{ width: 120, mx: 1 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Number</InputLabel>
                    <Select
                      id="demo-simple-select-label"
                      value={number}
                      label="Select Number"
                      onChange={(e) => calcNumber(e.target.value)}
                    >
                      <MenuItem value={0}>Select</MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box position='relative' display='flex' justifyContent='center'>
                  <Button sx={{ width: "120px", height: "55px", background: 'green', padding: '15px', borderRadius: "4px", color: "white", letterSpacing: '5px' }}
                    disabled={number >= 1 && !isPurchasing ? false : true}
                    onClick={() => pay()}
                  >
                    {isPurchasing ? "Paying..." : "Pay"}
                  </Button>
                </Box>
              </Box>
            </>
          )
        }

      </Box>
      {/* start content */}

      {/* modals */}
      <ConnectWalletModal
        open={openConnectWallet}
        handleClose={() => setOpenConnectWallet(false)}
      />

      <AccountDetailDialog
        open={openAccountDetailDialog}
        handleClose={() => setOpenAccountDetailDialog(false)}
        handleDisconnectDialog={() => setOpenDisconnectWalletDialog(true)}
      />

      <DisconnectWalletDialog
        open={openDisconnectWalletDialog}
        handleClose={() => setOpenDisconnectWalletDialog(false)}
      />

      {/* end modals */}
    </>
  )
}

export default HomePage;
