import { FC } from 'react';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import CustomButton from 'components/CustomButton';
import closeIcon from 'assets/img/close-green.png';
import styles from 'assets/jss/components/dialogStyles';
import { useKaddexWalletContext } from 'contexts';

const useStyles = makeStyles(styles);

interface Props {
  open: boolean;
  handleClose: () => void
}

const DisconnectWalletDialog: FC<Props> = (props) => {

  const { open, handleClose } = props;
  const classes = useStyles();

  const { disconnectWallet } = useKaddexWalletContext();

  const disconnectWalletDialog = () => {
    handleClose();
    disconnectWallet();

  }
  return (
    <Dialog onClose={() => {}} open={open} className={classes.dialog}>
      <Box className={classes.dialogContents}>
        <Box className={clsx(classes.header, classes.mintHeader)} position='relative'>
          <Typography variant='h6' fontWeight={800} fontFamily='Inter'>
            Disconnect wallet
          </Typography>
          <IconButton className={classes.closeBtn} onClick={handleClose}>
            <img src={closeIcon} width={14} height={14} alt="" />
          </IconButton>
        </Box>

        <Box>
          <Typography variant='body1' align='center'>
            Do you really want to disconnect your wallet?
          </Typography>
          <Box className={classes.divider} my={4}></Box>          
          <Box textAlign='center' mt={4} className={classes.mintBtn}>
            <CustomButton fullWidth variant='greenFill' onClick={()=>disconnectWalletDialog()}>
              Disconnect
            </CustomButton>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

export default DisconnectWalletDialog;
