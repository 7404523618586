/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, ReactNode } from 'react';
import useLocalStorage from 'hooks/useLocalStorage';
import { WalletObj } from 'constants/wallet';

export type WalletContextValue = {
  wallet: WalletObj,
  setSelectedWallet: (wallet: WalletObj) => void,
  disconnectWallet: () => void,
  removeWallet: () => void,
}

const initWalletObj: WalletObj = {
  id: '',
  name: '',
  signMethod: '',
  getAccountsUrl: '',
}

export const WalletContext = createContext<WalletContextValue>({
  wallet: initWalletObj,
  setSelectedWallet: (wallet: WalletObj) => null,
  disconnectWallet: () => null,
  removeWallet: () => null,
});

export const WalletProvider = ({ children }: { children: ReactNode}) => {
  const [wallet, setWallet, removeWallet] = useLocalStorage('wallet', '');


  const setSelectedWallet = (wallet: WalletObj) => {
    setWallet({ id: wallet.id, name: wallet.name });
  };

  const disconnectWallet = () => {
    removeWallet();

    window.location.reload();
  };


  return <WalletContext.Provider value={{ wallet, setSelectedWallet,  disconnectWallet, removeWallet }}>{children}</WalletContext.Provider>;
};

