/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, createContext, useEffect, useCallback, ReactNode } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAccountContext } from '.';
import { NETWORK, NETWORKID } from '../constants/contextConstants';
import { WALLET } from '../constants/wallet';
import useLocalStorage from '../hooks/useLocalStorage';

interface KaddexWalletState {
  isConnected: boolean,
  isInstalled: boolean,
  NETWORK: string,
  account: string
}

export type KaddexWalletContextValue = {
  kaddexWalletState: KaddexWalletState,
  initializeKaddexWallet: () => void,
  disconnectWallet: () => void,
  requestSign: (requestSignProps: any) => void,
}

const initialKaddexWalletState: KaddexWalletState = {
  isConnected: false,
  isInstalled: false,
  NETWORK: NETWORK,
  account: '',
};

export const KaddexWalletContext = createContext<KaddexWalletContextValue>({
  kaddexWalletState: initialKaddexWalletState,
  initializeKaddexWallet: () => null,
  disconnectWallet: () => null,
  requestSign: (requestSignProps: any) => null,
});

export const KaddexWalletProvider = ({ children }: { children: ReactNode }) => {
  const [kadenaExt, setKadenaExt] = useState({ });

  const [kaddexWalletState, setKaddexWalletState] = useLocalStorage('kaddexWalletState', JSON.stringify(initialKaddexWalletState));

  const { setVerifiedAccount, logout, account } = useAccountContext();

  const initialize = useCallback(() => {
    // @ts-ignore
    const { kadena } = window;
    setKadenaExt(kadena);
    setKaddexWalletState({
      ...kaddexWalletState,
      isInstalled: Boolean(kadena?.isKadena),
    });
  }, [kaddexWalletState]);

  useEffect(() => {
    window.addEventListener('load', initialize);
  }, [initialize]);

  // useEffect(() => {
  //   const registerEvents = async () => {
  //     if (kadenaExt) {
  //       kadenaExt.on('res_accountChange', async (response) => {
  //         console.log('X-Wallet: LISTEN res_accountChange', response);
  //         await checkStatus();
  //       });
  //       kadenaExt.on('res_checkStatus', onCheckStatusResponse);
  //       kadenaExt.on('res_sendKadena', (response) => {
  //         console.log('X-Wallet: LISTEN res_SendKadena', response);
  //       });
  //       kadenaExt.on('res_disconnect', () => {});
  //     }
  //   };
  //   registerEvents();
  //   if (kadenaExt && kaddexWalletState.isConnected) {
  //     setAccountData();
  //   }
  // }, [kadenaExt]);

  useEffect(() => {
    if (kaddexWalletState.isConnected && (!account?.account)) {
      disconnectWallet();
    }
  }, [ kaddexWalletState, account]);

  /**
   * Used by ConnectModal
   */
  const initializeKaddexWallet = async () => {
    console.log('!!!initializeKaddexWallet');
    const networkInfo = await getNetworkInfo();
    console.log('networkInfo', networkInfo);
    if (networkInfo.networkId !== NETWORKID) {
      showNetworkError();
    } else {
      const connectResponse = await connectWallet();
      console.log('connectResponse', connectResponse);
      if (connectResponse?.status === 'success') {
        await setAccountData();
      }
    }
  };

  const connectWallet = async () => {
    // @ts-ignore
    const connect = await kadenaExt.request({
      method: 'kda_connect',
      networkId: NETWORKID,
    });
    return connect;
  };

  const disconnectWallet = async () => {
    if (kadenaExt) {
      console.log('X-Wallet: SEND disconnect request');
      setKaddexWalletState({
        ...kaddexWalletState,
        account: null,
        isConnected: false,
      });

      // @ts-ignore
      await kadenaExt.request({
        method: 'kda_disconnect',
        networkId: NETWORKID,
      });
      logout();
    }
  };

  const getNetworkInfo = async () => {

    // @ts-ignore
    const network = await kadenaExt.request({
      method: 'kda_getNetwork',
    });
    console.log('X-Wallet: SEND kda_getNetwork request', network);
    return network;
  };

  const getAccountInfo = async () => {
    // @ts-ignore
    const account = await kadenaExt.request({
      method: 'kda_requestAccount',
      networkId: NETWORKID,
    });
    console.log('X-Wallet: SEND kda_requestAccount request', account);
    return account;
  };

  const requestSign = async (signingCmd: any) => {
    const account = await getAccountInfo();
    if (account.status === 'fail') {
      alertDisconnect();
    } else {
      console.log('signingCmd', signingCmd);
      // @ts-ignore
      return await kadenaExt.request({
        method: 'kda_requestSign',
        networkId: NETWORKID,
        data: signingCmd
      });
    }
  };

  const setAccountData = async () => {
    console.log('X-Wallet: SETTING ACCOUNT DATA');
    const acc = await getAccountInfo();
    console.log('account', acc)
    if (acc.wallet) {
      console.log('X-Wallet: SETTING ACCOUNT DATA - WALLET FOUNDED', acc);
      await setVerifiedAccount(acc.wallet.account, WALLET.KADDEX_WALLET);
      setKaddexWalletState({
        account: acc.wallet.account,
        isInstalled: true,
        isConnected: true,
      });

    } else if (kaddexWalletState.isConnected) {
      console.log('X-Wallet: SETTING ACCOUNT DATA - WALLET NOT FOUND CONNECTING');
      const connectRes = await connectWallet();
      if (connectRes.status === 'success') {
        await setAccountData();
      }
    } else {
      console.log('X-Wallet: SETTING ACCOUNT DATA - NOT CONNECTED');
    }
  };

  const alertDisconnect = () => {
    toast.warning('Wallet not connected');
    logout();
  };

  // const onCheckStatusResponse = async (response) => {
  //   // I have to use local storage directly because of state is null on callback listener
  //   const localState = localStorage.getItem('kaddexWalletState') && JSON.parse(localStorage.getItem('kaddexWalletState'));
  //   if (localState?.isConnected && response?.result?.status === 'fail' && response?.result?.message === 'Not connected') {
  //     const connectRes = await connectWallet();
  //     if (connectRes.status === 'success') {
  //       await setAccountData();
  //     }
  //   }
  // };

  const showNetworkError = () => {
    toast.warning(`Please set the correct network: ${NETWORKID}`);
  };

  // const showChainError = (selectedChain) => {
  //   showNotification({
  //     title: 'Wallet error',
  //     message: `Please set chain ${CHAIN_ID} ${selectedChain ? `(chain ${selectedChain} selected)` : ''}`,
  //     type: STATUSES.WARNING,
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //   });
  // };

  return (
    <KaddexWalletContext.Provider
      value={{
        ...kaddexWalletState,
        initializeKaddexWallet,
        disconnectWallet,
        requestSign,
      }}
    >
      {children}
    </KaddexWalletContext.Provider>
  );
};

export const KaddexWalletCunsomer = KaddexWalletContext.Consumer;
