import React, { useState } from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Layout from 'components/Layout';
import HomePage from 'views/HomePage';
import { AccountProvider } from 'contexts/AccountContext';
import { KaddexWalletProvider } from 'contexts/KaddexWalletContext';
import { WalletProvider } from 'contexts/WalletContext';
import { ToastContainer } from "react-toastify";
import { Buffer } from 'buffer';
Buffer.from('anything', 'base64');

const light = {
  palette: {
    mode: "light",
  },
};

const dark = {
  palette: {
    mode: "dark",
  },
};

function App() {
  const [isDarkTheme, setIsDarkTheme] = useState<boolean>(true);

  return (
    <BrowserRouter>
      <WalletProvider>
        <AccountProvider>
          <KaddexWalletProvider>
            {/* @ts-ignore */}
            <ThemeProvider theme={isDarkTheme ? createTheme(dark) : createTheme(light)}>
              <Layout>
                <Routes>
                  <Route path='/home' element={<HomePage />} />
                  <Route path='/' element={<HomePage />} />
                </Routes>
                <ToastContainer />
              </Layout>
            </ThemeProvider>
          </KaddexWalletProvider>
        </AccountProvider>
      </WalletProvider>
    </BrowserRouter>
  );
}

export default App;
